.inputGroup {
    composes: formikInput from './formikInput.vars.css';
    width: 100%;
    font-size: 16px;
    margin-bottom: 1em;
    position: relative;
}

.inputGroup:last-child {
    margin-bottom: 6px;
}

.inputLabel {
    color: var(--col--dark);
    display: inline-block;
    font-family: var(--formik-input-inputLabel-font-family);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.inputLabelStar {
    color: var(--formik-input-invalid-input-color);
}

.inputControl {
    border: solid 1px var(--formik-input-border-color);
    border-radius: var(--formik-input-border-radius);
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-family: var(--formik-input-inputControl-font-family);
    font-size: var(--formik-input-inputControl-font-size);
    padding-left: 20px;
}

textarea.inputControl {
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    resize: none;
    display: flex;
}

.inputControl:focus {
    box-shadow: none;
    border: solid 1px var(--col--gray6);
    border-radius: var(--formik-input-border-radius);
    outline: none;
}

.invalid .inputControl {
    border: solid 1px var(--formik-input-invalid-input-color);
    border-radius: var(--formik-input-border-radius);
}

.invalid .inputControl:not(:disabled) {
    border: solid 1px var(--formik-input-invalid-input-color);
}

.invalid .inputControl:not(:disabled):focus {
    border: solid 1px var(--formik-input-invalid-input-color);
    box-shadow: none;
    outline: none;
}

.valid .inputControl:not(:disabled) {
    border: solid 1px var(--col--gray6);
}

.valid .inputControl:not(:disabled):focus {
    border: solid 1px var(--col--gray6);
    box-shadow: none;
    outline: none;
}

.inputError {
    color: var(--formik-input-invalid-input-color);
    width: 100%;
    font-family: var(--formik-password-input-error-message);
    font-size: var(--formik-input-invalid-font-size);
    line-height: var(--formik-input-invalid-line-height);
    margin-top: var(--formik-input-invalid-margin-top);
}

.description {
    padding-top: 5px;
    font-size: var(--formik-input-description-font-size);
    line-height: 16px;
}

.tooltipIcon {
    display: block;
}

.labelGroup {
    display: flex;
    align-items: center;
    color: var(--col--dark);
    font-family: Arial, sens-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}

.tooltipWrapper {
    display: flex;
    margin-left: 5px;
}

.inputLabelOptional {
    margin-left: var(--formik-input-label-optional-margin-left);
    color: var(--formik-input-label-optional-color);
}
