.counter {
    composes: counter from './index.vars.css';
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 18px;
    background-color: var(--col--primary1);
    color: var(--counter-color);
    display: flex;
    align-items: var(--counter-align-items, center);
    justify-content: center;
}

@media (max-width: 959px) {
    .counter {
        font-size: var(--mobile-font-size);
        width: var(--mobile-width-and-height);
        height: var(--mobile-width-and-height);
    }
}
