.startOrderButton {
    composes: startOrderButton from './startOrderButton.vars.css';
    display: none;
}

@media (max-width: 960px) {
    .startOrderButton {
        background: var(--col--primary1);
        border-radius: var(--button-border-radius, 0);
        color: var(--button-color);
        display: var(--start-order-button-display, block);
        font-family: var(--button-font-family);
        font-size: var(--button-font-size);
        font-weight: var(--button-font-weight);
        line-height: var(--button-line-height);
        letter-spacing: var(--button-letter-spacing);
        text-transform: var(--button-text-transform, uppercase);
        height: var(--button-height, 60px);
        position: sticky;
        bottom: 0;
        z-index: 998;
    }

    .startOrderButton:hover {
        background: var(--button-background-hover);
    }
}
