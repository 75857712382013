.formikInput {
    --formik-input-invalid-input-color: var(--col--primary1);
    --formik-input-invalid-font-size: 80%;
    --formik-input-invalid-line-height: inherit;
    --formik-input-invalid-margin-top: 0.31em;
    --formik-input-border-radius: 0;
    --formik-input-border-color: var(--col--gray6);
    --formik-password-input-error-message: var(--ff--pharagraph);
    --formik-input-inputLabel-font-family: var(--ff--pharagraph);
    --formik-input-inputControl-font-family: var(--ff--pharagraph);
    --formik-input-inputControl-font-size: 16px;
    --formik-input-description-font-size: 12px;
    --formik-input-label-optional-color: var(--col--dark);
    --formik-input-label-optional-margin-left: 0;
}
