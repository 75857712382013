.container {
    background: var(--col--gray2);
    padding: 11px 30px;
    text-align: center;
    color: var(--col--dark);
}

.signUp {
    color: var(--col--primary1);
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 959px) {
    .container {
        padding-top: 12px;
        padding-bottom: 13px;
    }
}
