.closeButton {
    composes: closeButton from './closeButton.vars.css';
    font-family: var(--button-font-family);
    font-style: normal;
    font-weight: var(--button-font-weight);
    text-transform: var(--button-text-transform);
    color: var(--col--light);
    background: var(--col--dark);
    border-radius: 0;
    font-size: var(--button-font-size);
    line-height: var(--button-line-height);
    height: 66px;
}

.closeButton .closeButtonIcon {
    font-size: var(--button-icon-font-size);
    margin-right: 5px;
}

.buttonText {
    margin-top: var(--buttonText--margin-top, initial);
}
