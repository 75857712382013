.navigation {
    composes: navigation from './index.vars.css';
    background-color: var(--nav-background-color);
    color: var(--nav-color);
    box-shadow: var(--nav-box-shadow, none);
}

.wrapper,
.startOrderBtnWrapper {
    padding: 0 20px;
}

.wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: var(--navigation-wrapper-justify-content);
}

.logoContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 7px;
}

.centerContainer {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 56px;
    position: relative;
    justify-content: flex-end;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoWrapper {
    display: flex;
}

.linkContainer {
    display: none;
}

.navigationLinkWrapper {
    display: flex;
    align-items: center;
}

.navigationLinkWrapper a {
    margin-right: 6px;
}

.logoText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 4px;
    font-family: var(--link-label-font-family);
    text-transform: uppercase;
    font-weight: 800;
}

.logoText span:nth-child(1) {
    font-size: 14px;
    line-height: 12px;
}

.logoText span:nth-child(2) {
    font-size: 23px;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.logoText span:nth-child(3) {
    font-size: 19px;
    line-height: 15px;
}

.locationContainer {
    padding: 11.4px 0;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.locationName {
    font-family: var(--ff--link);
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
}

.locationName > span {
    font-size: 10px;
    margin-left: 5px;
}

.locationContainer .locationIcon {
    font-size: 10px;
    margin-left: 6.1px;
    margin-right: 4.4px;
}

.locationInfo {
    color: var(--col--gray5);
    font-family: var(--heavy-font);
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
}

.icon {
    font-size: 24px;
    display: none;
    cursor: pointer;
}

.menuIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuIcon::before {
    color: var(--menu-icon-color);
}

.locationLink {
    display: block;
    width: 100%;
}

.signInIconWrapper {
    position: relative;
    display: flex;
    margin-right: 10px;
}

.signInIconWrapper .iconDot {
    display: block;
    height: 8px;
    width: 8px;
    background-color: var(--col--primary1);
    border-radius: 50%;
    position: absolute;
    top: var(--icon-wrapper-icon-dot-top);
    right: 2px;
}

.startOrderBtnWrapper {
    display: var(--start-button-order-display, none);
}

.mobileCheckInBtnWrapper {
    display: none;
}

@media (max-width: 1299px) {
    .dekstopCheckInBtn {
        display: none;
    }

    .mobileCheckInBtnWrapper {
        display: block;
        border-top: 1px solid var(--col--gray4);
        padding: 10px 52px;
    }

    .mobileCheckInBtn {
        width: 100%;
    }
}

@media (max-width: 959px) {
    .navigation {
        padding-top: var(--nav-padding-top-mobile);
    }

    .centerContainer {
        flex-grow: var(--center-container-flex-grow);
        justify-content: var(--center-container-justify-content);
        margin-left: var(--center-container-margin-left);
        margin-right: var(--center-container-margin-right);
        height: var(--center-container-margin-height-mobile);
    }

    .logoContainer {
        height: var(--logo-container-height);
        position: var(--logo-container-position);
        left: 0;
        right: 0;
        margin: 0;
    }

    .logoText {
        display: none;
    }

    .logoImage {
        height: var(--logo-height-mobile);
        width: var(--logo-width-mobile);
    }

    .iconsContainer {
        margin-left: auto;
    }

    .arbysMobileLocationBlock {
        box-sizing: border-box;
        background-color: rgb(255, 255, 255, 0.2);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 18px;
        border-radius: 53px;
        height: 42px;
        cursor: pointer;
        width: calc(100% - 140px);
    }

    .arbysMobileLocationBlock .name {
        display: flex;
        align-items: baseline;
        max-width: calc(100% - 18px);
    }

    .arbysMobileLocationBlock .nameText {
        box-sizing: border-box;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        width: 100%;
    }

    .arbysMobileMapPinIcon {
        margin-right: 8px;
    }

    .startOrderBtnWrapper {
        padding: var(--mobile-start-order-button-pagging, 0 20px);
    }

    .mobileCheckInBtnWrapper {
        padding: 10px 20px;
    }
}

@media (min-width: 960px) {
    .wrapper,
    .startOrderBtnWrapper {
        padding: var(--tablet-header-padding, 0 32px);
    }

    .startOrderBtnWrapper {
        display: var(--media-start-button-order-display, block);
    }

    .wrapper {
        height: 85px;
        flex-wrap: nowrap;
    }

    .centerContainer {
        min-width: 700px;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon:not(:last-child) {
        margin-right: 35px;
    }

    .icon > * {
        padding-bottom: 6px;
    }

    .menuIcon {
        display: none;
    }

    .logoContainer {
        flex-grow: 0;
        margin-right: var(--logo-margin-right, 54px);
    }

    .logoImage {
        height: var(--logo-big-height);
        width: auto;
    }

    .linkContainer {
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
    }

    .navigationLinkWrapper {
        margin-right: 20px;
    }

    .iconsContainer {
        display: flex;
        padding: 13.5px 0 9.5px;
        order: 2;
        margin-left: 35px;
    }
}

@media (min-width: 1200px) {
    .wrapper,
    .startOrderBtnWrapper {
        padding: 0 52px;
    }

    .navigationLinkWrapper {
        margin-right: var(--navigationLinkWrapper-margin-right, 40px);
    }
}

@media (min-width: 1441px) {
    .wrapper,
    .startOrderBtnWrapper {
        padding: 0 52px;
    }
}

@media (min-width: 960px) and (max-width: 1299px) {
    .startOrderBtnWrapper {
        display: var(--lower-desktop-start-an-order-btn-display);
        border-top: 1px solid var(--col--gray4);
        padding-top: var(--startOrderBtnWrapper-padding-top-bottom, 22px);
        padding-bottom: var(--startOrderBtnWrapper-padding-top-bottom, 22px);
    }

    .startOrderBtn {
        width: 100%;
    }
}

.bagIcon {
    font-size: var(--icon-bag-font-size);
}
