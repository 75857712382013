.notificationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 47px;
}

.success {
    color: var(--col--secondary5);
}

.icon {
    margin-bottom: 32px;
}

.title {
    margin-bottom: 10px;
}

.notificationDescription {
    padding: 0 30px;
    text-align: center;
}

.errorDescription {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.error {
    color: var(--col--light);
    border-radius: 50%;
    background-color: var(--col--utilityError);
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    vertical-align: middle;
}

.warning {
    width: 60px;
    height: 60px;
    margin-bottom: 32px;
}

@media (max-width: 960px) {
    .notificationContainer {
        margin-top: 37px;
    }

    .errorDescription {
        display: inline;
    }

    .icon {
        margin-bottom: 25px;
    }

    .notificationDescription {
        padding: 0 12px;
    }
}
