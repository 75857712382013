.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContainer {
    outline: none;
    overflow: auto;
    position: absolute;
    top: 258px;
    display: flex;
    flex-direction: column;
    background: var(--col--light);
}

.modalHeader {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0 0;
}

.closeIcon {
    cursor: pointer;
}

@media (max-width: 960px) {
    .modalContainer {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
