.container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180.84deg, rgba(255, 255, 255, 0) 9.59%, #fff 28.81%);
    padding: 25px 20px;
}

.container button {
    width: 100%;
}

@media (min-width: 960px) {
    .container {
        position: relative;
        background: none;
        padding: 25px 20px;
    }
}
