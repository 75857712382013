.navigationMenu {
    --menuLinkContainer-link-label-color: var(--col--dark);
    --menuLinkContainer-link-label-font-size: 14px;
    --menuLinkContainer-link-label-line-height: 16px;
    --menu-action-bag-icon-margin-top: -4;
    --menu-action-button-font-size: 14px;
    --menu-action-button-line-height: 22px;
    --menu-action-button-font-family: var(--ff--pharagraph);
    --menu-action-button-font-weight: 700;
    --menu-action-button-text-transform: unset;
    --link-label-text-transform: capitalize;
    --link-label-padding: 13px 0;
    --menu-nav-link-label-font-family: var(--ff--pharagraph);
    --menu-nav-link-border-bottom: 4px solid var(--col--primary1);
    --bag-counter-top: -5px;
    --bag-counter-right: 0;
    --menu-action-button-text-margin-left: 0;
}
