.signIn {
    --signInText-color: var(--col--light);
    --signInText-font-family: var(--link-label-font-family);
    --signInText-min-width: 65px;
    --signInText-mobile-min-width: 50px;
    --notAuthenticated-font-size: 18px;
    --notAuthenticated-font-weight: 800;
    --notAuthenticated-mobile-font-size: 14px;
    --notAuthenticated-mobile-transform: capitalize;
    --notAuthenticated-mobile-line-height: 14px;
    --notAuthenticated-font-family: var(--link-label-font-family);
    --account-menu-link-font-family: var(--ff--pharagraph-strong);
    --logoutButton-font-size: 14px;
    --logoutButton-line-height: 18px;
    --logoutButton-font-weight: bold;
    --logoutButton-text-decoration-line: underline;
    --logoutButton-text-transform: uppercase;
    --chevron-icon-before-color: var(--col--light);
    --signInDivider-color: var(--col--gray6);
    --signInHorizontalDivider-color: var(--col--gray6);
    --dropdownSignInSection-border-color: var(--col--gray4);
    --dropdownSignInSection-padding: 13px;
    --logoutButton-mobile-bottom: 40px;
    --signInContainer-margin-right: 20px;
    --signInIconWrapper-margin-right: 10px;
    --signInAvatar-font-size: 24px;
    --icon-wrapper-icon-dot-top: -4px;
}

@media (max-width: 960px) {
    .signIn {
        --notAuthenticated-font-family: var(--ff--header);
    }
}
