.signInContainer {
    composes: signIn from './signIn.vars.css';
    min-height: 39px;
    display: flex;
    align-items: center;
    z-index: 1;
    overflow: hidden;
}

.signInIconWrapper {
    position: relative;
    display: flex;
    margin-right: 10px;
}

.signInIconWrapper .iconDot {
    display: block;
    height: 8px;
    width: 8px;
    background-color: var(--col--primary1);
    border-radius: 50%;
    position: absolute;
    top: var(--icon-wrapper-icon-dot-top);
    right: 0;
}

.avatarWrapper .iconDot {
    display: block;
    height: 8px;
    width: 8px;
    background-color: var(--col--primary1);
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -1px;
}

.signInButton {
    min-width: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.divider {
    margin-left: 35px;
    margin-right: 35px;
    width: 1px;
    background: var(--signInDivider-color);
    height: 30px;
}

.horizontalDivider {
    margin: 15px 0;
    height: 1px;
    background: var(--signInHorizontalDivider-color);
    width: 100%;
}

.signInText {
    font-family: var(--signInText-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: var(--signInText-color);
    min-width: var(--signInText-min-width);
}

.notAuthenticated {
    font-size: var(--notAuthenticated-font-size);
    font-weight: var(--notAuthenticated-font-weight);
    font-family: var(--notAuthenticated-font-family);
}

.chevronIcon {
    max-width: 8px;
    margin-left: 7px;
    flex: 1 0 auto;
}

.chevronIcon::before {
    color: var(--chevron-icon-before-color);
}

.accountMenulink {
    color: var(--col--light);
    display: inline-flex;
    align-items: center;
    font-family: var(--account-menu-link-font-family);
}

.itemContainer:not(:nth-child(1)) {
    margin-top: 15px;
}

.menuContent {
    min-width: 166px;
}

.logoutButton {
    composes: signIn from './signIn.vars.css';
    color: var(--col--light);
    font-family: var(--ff--pharagraph);
    font-size: var(--logoutButton-font-size);
    font-weight: var(--logoutButton-font-weight);
    line-height: var(--logoutButton-line-height);
    text-decoration-line: var(--logoutButton-text-decoration-line);
    text-transform: var(--logoutButton-text-transform);
}

.dropdownContent {
    composes: signIn from './signIn.vars.css';
}

.dropdownContent.dropdownContent {
    padding: 25px 30px;
    background-color: var(--col--dark);
}

.accountInfoContaner {
    margin-top: -4px;
}

.itemContainer {
    display: flex;
    align-items: center;
}

.counter {
    margin-left: 10px;
}

.rewardsInfo {
    white-space: pre-wrap;
    display: flex;
    margin-top: -4px;
}

.rewardsInfoMobile {
    white-space: pre;
    color: var(--col--light);
    font-weight: 500;
}

.avatarWrapper {
    position: relative;
    margin-right: 10px;
    height: 32px;
    width: 32px;
    filter: drop-shadow(2px 2px 2px rgba(24, 52, 77, 0.1));
    box-sizing: border-box;
    border: 1px solid var(--col--light);
    border-radius: 50%;
}

.avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

@media (max-width: 1360px) {
    .divider {
        margin: 0;
        margin-left: 10px;
        margin-right: 19px;
    }
}

@media (max-width: 960px) {
    .signInIconWrapper {
        margin-right: var(--signInIconWrapper-margin-right);
    }

    .dropdownSignInText > span:first-child {
        margin-right: 10px;
    }

    .dropdownContent.dropdownContent.dropdownContent {
        padding: 0;
        background-color: var(--col--light);
    }

    .notAuthenticated {
        font-size: var(--notAuthenticated-mobile-font-size);
        line-height: var(--notAuthenticated-mobile-line-height);
        text-transform: var(--notAuthenticated-mobile-transform);
    }

    .itemContainer {
        display: flex;
        align-items: center;
        height: 84px;
        border-bottom: 1px solid var(--col--gray4);
        justify-content: center;
    }

    .itemContainer:not(:nth-child(1)) {
        margin-top: 0;
    }

    .signInText {
        min-width: var(--signInText-mobile-min-width);
        color: var(--signInText-color);
    }

    .accountMenulink {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: var(--col--dark);
    }

    .dropdownSignInSection {
        background-color: var(--col--dark);
        justify-content: center;
        display: flex;
        align-items: center;
        padding: 13px;
        color: var(--col--light);
    }

    .dropdownSignInHorizontalDivider {
        height: 1px;
        background-color: var(--dropdownSignInSection-border-color);
    }

    .wrapperDropdownSignIn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .signInText.dropdownSignInText {
        display: initial;
        color: var(--col--light);
    }

    .chevronIcon {
        display: none;
    }

    .divider {
        display: none;
    }

    .horizontalDivider {
        display: none;
    }

    .signInContainer {
        margin-right: var(--signInContainer-margin-right);
    }

    .isAuthenticated {
        display: none;
    }

    .logoutButton {
        color: var(--col--dark);
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 166px;
        bottom: var(--logoutButton-mobile-bottom);
        text-align: center;
    }

    .rewardsInfo {
        display: none;
    }

    .accountInfoContaner {
        margin-top: 0;
    }
}

.signInLoading {
    color: var(--col--primary1);
    margin-right: 25px;
}

.signInAvatar {
    font-size: var(--signInAvatar-font-size);
}
