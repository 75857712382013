.container {
    display: flex;
    padding: 12px 20px 100px;
    flex-direction: column;
    align-items: center;
}

.ohno {
    color: var(--col--primary1);
    font-style: italic;
}

.title {
    margin: 0;
    font-size: 30px;
    line-height: 30px;
}

.icon {
    margin: 33px 0 10px;
    display: flex;
    width: 160px;
    height: 160px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--col--gray2);
}
