.navigation {
    --nav-background-color: var(--col--dark);
    --nav-color: var(--col--light);
    --nav-padding-top-mobile: 17px;
    --logo-width-mobile: 34px;
    --logo-height-mobile: 32px;
    --logo-big-height: 55px;
    --logo-container-position: relative;
    --logo-container-height: auto;
    --menu-icon-color: var(--col--light);
    --lower-desktop-start-an-order-btn-display: none;
    --navigation-wrapper-justify-content: flex-start;
    --center-container-justify-content: flex-start;
    --center-container-flex-grow: initial;
    --center-container-margin-left: 18px;
    --center-container-margin-right: 18px;
    --center-container-margin-height-mobile: auto;
    --icon-bag-font-size: 24px;
    --media-start-button-order-display: none;
    --icon-wrapper-icon-dot-top: -4px;
}
