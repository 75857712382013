.container {
    display: flex;
    padding: 12px 20px 100px;
    flex-direction: column;
}

.ohno {
    color: var(--col--primary1);
    font-style: italic;
}

.title {
    margin: 0;
    font-size: 30px;
    line-height: 30px;
}

.imageContainer {
    margin: 33px auto 10px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: var(--col--gray2);
}

.description {
    text-align: center;
}
