.container {
    padding: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    background: var(--col--primary1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorIcon {
    padding-right: 12px;
}

@media (max-width: 425px) {
    .container {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        padding: 16px 20px;
        align-items: flex-start;
    }

    .errorIcon {
        padding-top: 3px;
        font-size: 14px;
        padding-right: 8px;
    }
}
