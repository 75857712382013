.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    outline: none;
    position: relative;
    background-color: var(--col--light);
    box-sizing: border-box;
    width: 100vw;
    height: var(--app-height);
    padding: 20px;
    padding-top: 50px;
}

.closeIcon {
    position: absolute;
    font-size: 18px;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.closeIcon::before {
    transform: rotate(45deg);
    color: var(--col--dark);
}

.closeIcon::after {
    transform: rotate(-45deg);
}

.subheader {
    margin-bottom: 8px;
    color: var(--col--primary1);
}

.header {
    margin: 0;
    margin-bottom: 13px;
}

.buttons {
    margin-top: 19px;
}

.button {
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 15px;
    white-space: nowrap;
}

.button:last-child {
    margin-bottom: 0;
    margin-left: 0;
}

@media (min-width: 960px) {
    .content {
        padding: 40px;
        padding-top: 60px;
        width: min-content;
        height: auto;
        box-shadow: inset 0px -5px 0px 0px var(--col--primary1);
    }

    .header {
        margin-bottom: 16px;
    }

    .closeIcon {
        right: 24px;
        top: 24px;
        font-size: 24px;
    }

    .subheader {
        margin-bottom: 0;
    }

    .buttons {
        margin-top: 30px;
        display: flex;
    }

    .button {
        margin-bottom: 0;
    }

    .button:last-child {
        margin-left: 20px;
    }
}
