.container {
    padding: 17px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.linkWrapper {
    display: flex;
    align-items: center;
}

.linkWrapper a {
    margin-right: 5px;
}

.hideMobileNavigationLinks {
    display: none;
}

.linkActive {
    padding-bottom: 10px;
    border-bottom: 3px solid var(--col--primary1);
    margin-bottom: -13px;
}

.linkLabel {
    font-size: 14px;
    line-height: 16px;
}
