.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px 25px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.icon {
    display: flex;
    width: 160px;
    height: 160px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--col--gray2);
}

.title {
    margin: 20px 0 14px;
}

.btn {
    margin: 0 3px;
}
