.alertBannersContainer {
    display: flex;
    flex-direction: column;
}

.alertBanner {
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    height: 44px;
}

.linkText {
    composes: linkText from './alertBanners.vars.css';
    text-transform: uppercase;
    font-size: var(--alert-banner-text-font-size);
    font-weight: var(--alert-banner-text-font-weight);
}

@media (max-width: 425px) {
    .linkText {
        width: 70%;
        margin: 0 auto;
    }
}

.closeIcon {
    position: absolute;
    right: 52px;
    cursor: pointer;
}

.closeIcon:focus:not(:focus-visible) {
    outline: none;
}

@media (max-width: 960px) {
    .alertBanner {
        height: 40px;
    }

    .closeIcon {
        right: 22px;
    }
}
