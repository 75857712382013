.content.modalContent {
    padding: 58px 54px 134px;
}

.modalTitle {
    text-align: center;
    font-size: 38px;
    line-height: 38px;
}

.modalContent .modalMessage {
    margin: 30px 0 27px;
}

.formContainer {
    padding: 0 16px 10px;
    text-align: start;
}

.inputLabel {
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 12px;
}

.input {
    color: var(--col--gray6);
    line-height: 24px;
    border: 1px solid var(--col--gray6);
}

.contentBorder {
    height: 1px;
    background-color: var(--col--gray4);
    margin: 0 -25px;
}

.checkBoxContainer {
    display: flex;
    align-items: center;
    margin-top: 35px;
}

.checkbox {
    display: inline-block;
    margin-right: 9px;
}

.checkBoxLabel {
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    text-align: start;
}

.modalButton {
    position: absolute;
    bottom: 50px;
}

@media (max-width: 959px) {
    .content.modalContent {
        padding: 80px 20px;
    }

    .formContainer {
        padding: 0 0 26px;
    }

    .contentBorder {
        margin: 0 -20px 0 0;
    }

    .modalTitle {
        font-size: 28px;
        line-height: 28px;
    }

    .inputError {
        position: absolute;
    }

    .modalButton {
        width: calc(100% - 40px);
    }
}

@media (min-width: 960px) {
    .modalContent .modalMessage {
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: 787;
    }

    .modalButton {
        left: 87px;
        width: calc(100% - 174px);
    }
}
