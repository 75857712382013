.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    outline: none;
    position: relative;
    width: 530px;
    height: 431px;
    display: flex;
    flex-direction: column;
    background-color: var(--col--light);
    box-sizing: border-box;
}

.closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.buttonRow {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    justify-content: center;
    width: 100%;
}

.closeIcon {
    color: var(--col--dark);
}

@media (max-width: 960px) {
    .content {
        overflow-y: visible;
        width: 100%;
        height: 100%;
    }

    .closeButton {
        right: 24px;
        top: 24px;
    }

    .buttonRow {
        bottom: 0;
    }

    .closeBtn {
        width: 100%;
        filter: drop-shadow(0px -5px 15px rgba(0, 0, 0, 0.15));
    }
}
