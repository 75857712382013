.container {
    display: flex;
    padding: 12px 20px 100px;
    flex-direction: column;
}

.imhere {
    color: var(--col--primary1);
    font-style: italic;
}

.title {
    margin: 0;
    font-size: 34px;
    line-height: 34px;
}

.stallNumberTitle {
    margin: 48px 0 10px;
}

.inputLabel {
    font-size: 12px;
    line-height: 12px;
}

.input {
    color: var(--col--primary2);
}

.link {
    text-decoration: underline;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
}
