.container {
    display: flex;
    padding: 12px 20px 100px;
    flex-direction: column;
}

.container .subtitle {
    color: var(--col--primary1);
}

.title {
    margin: 0;
    font-size: 34px;
    line-height: 34px;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 63px;
}

.content > p {
    margin-top: 10px;
    padding: 0 20px;
}

.icon {
    display: flex;
    width: 160px;
    height: 160px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--col--gray2);
}
