.skipLink {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    z-index: 1000;
    padding: 17px 54px;
    min-height: 60px;
    font-family: var(--subhead-font);
    font-size: 26px;
    line-height: 26px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    background-color: var(--col--light);
    color: var(--col--dark);
    transform: translate(-50%, -100%);
    border: 0;
    border-radius: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    outline: none;
}

.skipLink:focus {
    top: 17px;
    transform: translate(-50%, 0);
}
