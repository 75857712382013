.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 43px 20px 105px;
    text-align: center;
}

.distance {
    padding-top: 15px;
}

.locationDetailsHeader {
    color: var(--col--primary1);
}

.locationDetailsAddress {
    text-decoration: underline;
    text-underline-offset: 4px;
}

.locationDetails {
    padding-top: 15px;
}

.btn {
    width: 100%;
}

.textMeALink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 34px 25px;
    background: var(--col--gray1);
    margin-top: 35px;
}

.textMeALink button {
    align-self: center;
    margin-top: 30px;
}

.textMeALinkDescription {
    max-width: 297px;
}

.title {
    margin: 40px 0 14px;
}

.divider {
    margin-top: 15px;
}

.loader svg {
    padding-top: 40px;
    width: 60px;
}

.loader {
    height: 96px;
}
