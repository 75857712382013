.navigationMenu {
    composes: navigationMenu from './navigationMenu.vars.css';
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--col--light);
}

.navMenuTopSeparator {
    width: 100%;
    height: 1px;
    background: var(--col--dark);
    opacity: 0.75;
}

.signInWrapper {
    background: var(--col--dark);
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.signInWrapper * {
    color: var(--col--light);
}

.signInWrapper .signInIcon,
.menuActionButton .signInIcon,
.menuActionButton .menuActionBagIcon {
    font-size: 22px;
}

.signInWrapper .signInIcon div,
.menuActionButton .signInIcon div {
    right: 2px;
}

.signInWrapper .signInText {
    display: inline;
}

.logout {
    text-decoration: underline;
    font-weight: 800;
    font-size: 11px;
    line-height: 11px;
    font-family: var(--ff--pharagraph);
    position: absolute;
    right: 25px;
    cursor: pointer;
}

.menuLinkContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.menuLinkContainer .linkWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--menuLinkContainer-padding, 26px 0);
    text-align: center;
    border-bottom: 1px solid var(--col--gray4);
}

.menuLinkContainer .linkLabel {
    color: var(--menuLinkContainer-link-label-color);
    font-size: var(--menuLinkContainer-link-label-font-size);
    line-height: var(--menuLinkContainer-link-label-line-height);
    padding: var(--link-label-padding);
    text-transform: var(--link-label-text-transform);
    font-family: var(--menu-nav-link-label-font-family);
}

.menuLinkContainer .linkActive .linkLabel {
    border-bottom: var(--menu-nav-link-border-bottom);
}

.navigationMenuLinkCounter {
    margin-left: 10px;
    width: 20px;
    height: 20px;
}

.menuActionContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--menu-action-container-margin-bottom, 30px);
}

.menuActionButton {
    text-transform: var(--menu-action-button-text-transform);
    border-radius: 0;
}

.menuActionButton .menuActionButtonText {
    font-family: var(--menu-action-button-font-family);
    font-style: normal;
    font-weight: var(--menu-action-button-font-weight);
    color: var(--col--dark);
    font-size: var(--menu-action-button-font-size);
    line-height: var(--menu-action-button-line-height);
}

.menuActionButtonTextIndent {
    margin-left: var(--menu-action-button-text-margin-left);
}

.menuActionButton:first-child {
    padding-right: 15px;
}

.menuActionButton:nth-child(2) {
    border-left: 1px solid var(--col--gray4);
    padding-left: 15px;
}

.navigationMenuBagWrapper {
    position: relative;
    display: flex;
}

.menuActionBagIcon {
    color: var(--col--dark);
    margin-top: var(--menu-action-bag-icon-margin-top);
    margin-right: 10px;
}

.bagCounter {
    position: absolute;
    top: var(--bag-counter-top);
    right: var(--bag-counter-right);
}

.startOrderButton {
    display: block;
    margin: var(--startOrderBtn-margin, 0);
}
