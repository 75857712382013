.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 20px 105px;
    text-align: center;
    align-items: center;
}

.title {
    margin: 21px 0 14px;
}

.icon {
    display: flex;
    width: 160px;
    height: 160px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--col--gray2);
}
